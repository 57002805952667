<template>
  <ion-page>
    <ion-tabs>
      <ion-tab-bar slot="bottom" color="dark" class="ion-padding">
        <ion-tab-button tab="tab1" href="/almacen/orders">
          <ion-icon :icon="hammer" />
          <ion-label>Mis ordenes</ion-label>
        </ion-tab-button>
        <ion-tab-button tab="tab2" href="/system/logout">
          <ion-icon :icon="logOutOutline"></ion-icon>
          <ion-label>Salir</ion-label>
        </ion-tab-button>
      </ion-tab-bar>
    </ion-tabs>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonLabel,
  IonIcon,
} from "@ionic/vue";
import { hammer, logOutOutline, triangle } from "ionicons/icons";
export default {
  name: "footerApp",
  components: {
    IonPage,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonLabel,
    IonIcon,
  },
  setup() {
    return { hammer, logOutOutline, triangle };
  },
};
</script>
<style>
ion-tab-button::part(native) {
  font-size: 15px;
}
</style>
